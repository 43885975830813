























































































































































































































































































































































































































































































































































































































































































































































































































import truncate from 'vue-truncate-collapsed';
import { Component, Watch, Prop } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import Multiselect from 'vue-multiselect';
import TasqMixin from '@/lib/mixins/TasqMixin';
import { Debounce } from 'vue-debounce-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import { getComponent, getConfigEnv, sleep } from '@/utils/helpers';
import { DateTime } from 'luxon';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import workflowModule from '@/store/modules/workflowModule';
import assetsModule from '@/store/modules/assetsModule';
import accountModule from '@/store/modules/accountModule';
import {
  CHART_CLICK_EVENT, ALL_TASQS_LIST_ITEM, SHOW_ALERT, TASQ_OFF_TARGET_TYPE, TASQ_WAITING_ON_LIST,
} from '@/lib/constants';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import proceduresModule from '@/store/modules/proceduresModule';
import { getNameByEmail } from '@/utils/users';
import workspaceModule from '@/store/modules/workspaceModule';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    truncate,
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    SetpointFeedbackInfoV3: () => getComponent('tasqs/SetpointFeedbackInfoV3'),
    TasqFeedbackResponseSection: () => getComponent('tasqs/TasqFeedbackResponseSectionV2'),
    TasqHistory: () => getComponent('tasqs/TasqHistory'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    ScheduleModal: () => getComponent('common/ScheduleModal'),
    LineChart: () => import('@/lib/charts/lineChart'),
    Multiselect,
  },
})
export default class TasqFeedbackV4 extends mixins(DataLoading, TasqMixin) {
  @Prop({ type: Boolean, required: false, default: false }) isOpsPage ?: boolean;

  // Validate
  selectionAction: any = '';

  // Systems
  systems: string[] = []

  // Symptoms
  symptoms: string[] = []

  // Causes
  causes: string[] = []

  // Actions
  actions: string[] = []

  systemsComment: string = ''

  symptomsComment: string = ''

  causesComment: string = ''

  actionsComment: string = ''

  systemOptionsSelected = []

  symptomOptionsSelected = []

  causeOptionsSelected = []

  actionOptionsSelected = []

  isSavingProcedureStep = false

  isSearchingProcedureOptions = false

  waitingOnCompletePopupFocused = false;

  searchingProcedureText: string = ''

  showingCommentIDs: string[] = []

  childKey = 0

  // **** TOOLBARS SHOWING ******

	showResponseSectionDropdown = true

	showActivitySectionDropdown = false

	showCommentSectionDropdown = false

	isSavingReassignPopup = false

	returnedToNormalLoading = false

	showModal = false

	reassignmentComment = ''

 action: any = {
   assetType: '',
   type: '',
   title: '',
   confirmText: '',
   reason: [],
   person: '',
   comment: '',
   startDate: (new Date()).toDateString(),
   date: '',
   well: '',
   jobType: '',
   frequency: 'Today',
   delayedDays: 0,
   trigger: 'Feedback',
 };

  actionErrors: any = []

  setpointDetailsWellHistory = null

  showSetpointWellHistoryDetailsPopup = false

  chartLoaded = false;

  defermentLabelingModal = false;

  isLoadingSetpointData = false;

  showJobDetailsPopup = false;

  stepOneVariant = '';

  datetime = '';

  selectionActions = [
    {
      text: 'Yes',
      value: 'YES',
	  style: 'FULL',
    },
    {
      text: 'No',
      value: 'NO',
	  style: 'FULL',
    },
    {
      text: 'Not sure',
      value: 'DONT_KNOW',
	  style: 'SUB',
    },
  ];

  showComment = false;

  showActionComment = false;

  predictionAdditionalAction = '';

  setpointComment = '';

  comment = '';

  actionComment = '';

  standardReasons = getConfigEnv('FEEDBACK_REASONS');

  reason = [];

  stepTwoVariant = '';

  get activeActionTab() {
    // console.log(tasqFeedbackModule.selectedActionDetails);
    return tasqFeedbackModule.selectedActionDetails;
  }

  get waitingOnOptions() {
    return metaDataModule.waitingOnList;
  }

  get activeActionTabId() {
    return tasqFeedbackModule.selectedActionInputTab;
  }

  get completionOptionButtonTitles() {
    if (this.activeActionTabId) {
      const btnOptions: any = [];
      const result = this.activeActionTab.payload;
      if (result.completedForToday) {
        btnOptions.push('Complete for today');
      }
      if (result.reassing) {
        btnOptions.push('Complete & Reassign');
      }
      if (result.schedule) {
        btnOptions.push('Schedule this Tasq');
      }
      btnOptions.push('Not the same issue');

      return btnOptions;
    }
    return [

      //   "Create waiting on status"
    ];
  }

  jobAction = [];

  jobActions = [
    'Changed setpoint',
    'On site fix',
    'Change setpoint',
    'Troubleshooting',
    'No action needed',
    'Other',
  ];

  stepThreeVariant = '';

  foundProcedure = null;

  activityShown = 'history';

  activeEvent = '';

  submittingSetpointData = false;

  showFeedbackCompletionDropdown = false

  finishCompletionSectionButton = 'Next'

  reassignCompletePopupFocused = false

  subPredictionList: any = [];

  get feedbackNextButton() {
	  if (this.stepper < 3) {
		  return 'Next';
	  }
		  return this.finishCompletionSectionButton;
  }

  get reassignUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  get isMobile() {
    return this.windowWidth < 769;
  }

  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  get selectionActionFeedback() {
	  return tasqFeedbackModule.validationResponse.selectionAction;
  }

  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
      if (!tasqsListModule.activeTasq) {
        	tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      	}
    }

    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      return tasqsListModule.activeTasq;
    }
    // @ts-ignore
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level == 'PAD') {
      return tasqsListModule.activeTasq;
    }
    if (tasqsListModule.activeTasq) {
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }

  closeDialog() {
    this.showModal = false;
  }

  nextStep() {
    tasqFeedbackModule.setStepper(2);
  }

  getPredictionList() {
    if (this.activeTasq && this.activeTasq.predictionsList && Object.keys(this.activeTasq.predictionsList)) {
      let found = false;
      this.subPredictionList = Object.keys(this.activeTasq.predictionsList).map((data) => {
        const object:any = this.activeTasq?.predictionsList[data];
        if (data === this.activeTasq?.overriddenName) {
          found = true;
        }
        if (data === 'Off-Target RT') {
          data = 'REAL-Time';
        }
        return { name: data, predictionId: object.PredictionID, color: this.bgColorClass(data) };
      });
      if (!found) {
        this.subPredictionList.push({ name: this.activeTasq.engineerType, predictionId: this.activeTasq.id, color: this.bgColorClass(this.activeTasq.overriddenName) });
      }
    } else {
      this.subPredictionList = [];
    }
  }

  setShowFeedbackCompletionDropdown() {
    this.showFeedbackCompletionDropdown = !this.showFeedbackCompletionDropdown;
  }

  clickedCompletionOptionButtonTitle(completionOptionButtonTitle) {
    this.finishCompletionSectionButton = completionOptionButtonTitle;
    this.showFeedbackCompletionDropdown = false;
  }

  get stepper() {
    return tasqFeedbackModule.stepper;
  }

  stepperIsActive(step) {
    return tasqFeedbackModule.feedbackSteps[step - 1].active;
  }

  isLoadingProcedureStep(selectionAction) {
	   return tasqFeedbackModule.validationResponse.selectionAction == selectionAction.value && this.isSavingProcedureStep;
  }

  windowWidth = window.innerWidth;

  setDropdownIfAllSelected() {

  }

  setShowResponseSectionDropdown() {
	  this.showResponseSectionDropdown = !this.showResponseSectionDropdown;
	  this.setDropdownIfAllSelected();
	  if (this.showResponseSectionDropdown && this.showActivitySectionDropdown && this.showCommentSectionDropdown) {
		  this.showActivitySectionDropdown = false;
	  }
	  if (this.isMobile) {
		  this.showCommentSectionDropdown = false;
		  this.showActivitySectionDropdown = false;
	  }
  }

  setShowActivitySectionDropdown() {
	  this.showActivitySectionDropdown = !this.showActivitySectionDropdown;
	  this.setDropdownIfAllSelected();
	  if (this.showResponseSectionDropdown && this.showActivitySectionDropdown && this.showCommentSectionDropdown) {
		  this.showCommentSectionDropdown = false;
	  }
	  if (this.isMobile) {
		  this.showCommentSectionDropdown = false;
      //   this.showResponseSectionDropdown = false
	  }
  }

  setShowCommentSectionDropdown() {
	  this.showCommentSectionDropdown = !this.showCommentSectionDropdown;
	  this.setDropdownIfAllSelected();
	  if (this.showResponseSectionDropdown && this.showActivitySectionDropdown && this.showCommentSectionDropdown) {
		  this.showActivitySectionDropdown = false;
	  }
	  if (this.isMobile) {
		  this.showActivitySectionDropdown = false;
      //   this.showResponseSectionDropdown = false
	  }
  }

  getResponseSectionDropdownIcon() {
	  if (this.showResponseSectionDropdown) {
      return 'keyboard_arrow_down';
	  }
	  return 'keyboard_arrow_up';
  }

  getActivitySectionDropdownIcon() {
	  if (this.showActivitySectionDropdown) {
      return 'keyboard_arrow_down';
	  }
	  return 'keyboard_arrow_up';
  }

  getCommentSectionDropdownIcon() {
	  if (this.showCommentSectionDropdown) {
      return 'keyboard_arrow_down';
	  }
	  return 'keyboard_arrow_up';
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  formatBubbleText(val) {
	  return this.toTitleCase(val.toLowerCase());
  }

  filterBubbleValue(val) {
    if (this.searchingProcedureText !== '' && this.isSearchingProcedureOptions) {
      if (val.toLowerCase().startsWith(this.searchingProcedureText.toLowerCase())) {
        return false;
      }
      return true;
    }
    if (val == '') {
      return true;
    }
    return false;
  }

  handleInput(e) {
	  if (this.searchingProcedureText != '') {

	  }
  }

  systemIsSelected(system) {
	  let check_string = system.text;
	  check_string = `${system.title}<>${system.text}`;
	  if (this.systems.includes(check_string)) {
		  return true;
	  }
	  return false;
  }

  selectedSystem(system) {
	  let check_string = system.text;
	  check_string = `${system.title}<>${system.text}`;

	  if (this.systems.includes(check_string)) {
      const index = this.systems.indexOf(check_string);
      if (index > -1) {
        this.systems.splice(index, 1);
      }
	  } else {
		  this.systems.push(check_string);
	  }
  }

  symptomIsSelected(symptom) {
	  let check_string = symptom.text;
	  check_string = `${symptom.title}<>${symptom.text}`;
	  if (this.symptoms.includes(check_string)) {
		  return true;
	  }
	  return false;
  }

  selectedSymptom(symptom) {
	  let check_string = symptom.text;
	  check_string = `${symptom.title}<>${symptom.text}`;
	  if (this.symptoms.includes(check_string)) {
      const index = this.symptoms.indexOf(check_string);
      if (index > -1) {
        this.symptoms.splice(index, 1);
      }
	  } else {
		  this.symptoms.push(check_string);
	  }
  }

  causeIsSelected(cause) {
	  let check_string = cause.text;
	  check_string = `${cause.title}<>${cause.text}`;
	  if (this.causes.includes(check_string)) {
		  return true;
	  }
	  return false;
  }

  selectedCause(cause) {
	  let check_string = cause.text;
	  check_string = `${cause.title}<>${cause.text}`;
	  if (this.causes.includes(check_string)) {
      const index = this.causes.indexOf(check_string);
      if (index > -1) {
        this.causes.splice(index, 1);
      }
	  } else {
		  this.causes.push(check_string);
	  }
  }

  actionIsSelected(action) {
	  let check_string = action.text;
	  check_string = `${action.title}<>${action.text}`;
	  if (this.actions.includes(check_string)) {
		  return true;
	  }
	  return false;
  }

  selectedAction(action) {
	  let check_string = action.text;
	  check_string = `${action.title}<>${action.text}`;
	  if (this.actions.includes(check_string)) {
      const index = this.actions.indexOf(check_string);
      if (index > -1) {
        this.actions.splice(index, 1);
      }
	  } else {
		  this.actions.push(check_string);
	  }
  }

  // Version of json s3 file
  get jsonVersion() {
	  return proceduresModule.proceduresJsonVersion;
  }

  get isOnMobile() {
	  return this.windowWidth < 769;
  }

  @Watch('reason')
  updateReasonValue(value) {
    // @ts-ignore
    if (value.find((j) => j.includes('Other'))) {
      // @ts-ignore
      this.showComment = true;
    }
  }

  get setpointNameRowData() {
	  const results: any[] = [];
	  if (this.setpointDetailsWellHistory == null) {
		  return results;
	  }
	  const mapSetpointNames = {
		  open_to_close: 'Flow timer close',
		  close_flowrate: 'Flowrate close',
		  flowrate_time_limit: 'Flowrate low time limit',
		  evaluation_time_limit: 'Evaluation time limit',
		  open_lift_pressure: 'Case-Line Open',
		  close_to_open: 'Closed timer',
		  'Open Timer': 'Flow timer close',
		  'Close time': 'Closed timer',
		  'Close Time': 'Closed timer',
		  'Closed timer': 'Closed timer',
		  'Plunger Fall Delay': 'Hold plunger fall timer',
	  };

	  results.push('Afterflow timer close');
	  results.push('Flowrate low time limit');
	  results.push('Closed timer');
	  for (const [key, value] of Object.entries(this.setpointDetailsWellHistory!['Current Setpoints'])) {
      if (mapSetpointNames[key] != null) {
        results.push(mapSetpointNames[key]);
      }
	  }
	  return results;
  }

  get setpointEstimatedCurrentRowData() {
	  const results: any[] = [];
	  if (this.setpointDetailsWellHistory == null) {
		  return results;
	  }
	  const mapSetpointNames = {
		  open_to_close: 'Flow timer close',
		  close_flowrate: 'Flowrate close',
		  flowrate_time_limit: 'Flowrate low time limit',
		  evaluation_time_limit: 'Evaluation time limit',
		  open_lift_pressure: 'Case-Line Open',
		  close_to_open: 'Closed timer',
		  'Open Timer': 'Flow timer close',
		  'Close time': 'Closed timer',
		  'Close Time': 'Closed timer',
		  'Closed timer': 'Closed timer',
		  'Plunger Fall Delay': 'Hold plunger fall timer',
	  };

	  results.push('-');
	  results.push('-');
	  results.push('-');
	  for (const [key, value] of Object.entries(this.setpointDetailsWellHistory!['Current Setpoints'])) {
      if (mapSetpointNames[key] != null) {
        // @ts-ignore
        results.push(parseFloat(value).toFixed(2));
      }
	  }
	  return results;
  }

  get setpointRecommendedRowData() {
	  const results: any[] = [];
	  if (this.setpointDetailsWellHistory == null) {
		  return results;
	  }
	  const mapSetpointNames = {
		  open_to_close: 'Flow timer close',
		  close_flowrate: 'Flowrate close',
		  flowrate_time_limit: 'Flowrate low time limit',
		  evaluation_time_limit: 'Evaluation time limit',
		  open_lift_pressure: 'Case-Line Open',
		  close_to_open: 'Closed timer',
		  'Open Timer': 'Flow timer close',
		  'Close time': 'Closed timer',
		  'Close Time': 'Closed timer',
		  'Closed timer': 'Closed timer',
		  'Plunger Fall Delay': 'Hold plunger fall timer',
	  };
	  results.push('Disable');
	  results.push('0.5');
	  results.push('Disabled');
	  for (const [key, value] of Object.entries(this.setpointDetailsWellHistory!['Optimal Setpoints'])) {
      if (mapSetpointNames[key] != null) {
        // @ts-ignore
        results.push(parseFloat(value).toFixed(2));
      }
	  }
	  return results;
  }

  closeBatchResponseFeedbackSidebar() {
	  this.$emit('close-editing');
  }

  closeSetpointWellHistoryModal() {
	  this.showSetpointWellHistoryDetailsPopup = false;
	  this.setpointDetailsWellHistory = null;
  }

  get getUplift(): any {
    try {
      const rounded_value = setpointV2Module.uplift.toFixed(0);
      return rounded_value;
    } catch (err) {
      return setpointV2Module.uplift;
    }
  }

  get feedbackSteps() {
	  return tasqFeedbackModule.feedbackSteps;
  }

  get getDefermentUnits(): any {
    return setpointV2Module.unitType;
  }

  async acceptSetpointChanges() {
    this.comment = this.setpointComment;
    this.submittingSetpointData = true;
    this.selectionAction = 'YES';
    // @ts-ignore
    this.reason = ['Setpoint Accepted'];

    // const setpointData = setpointV2Module.tasqSetpointData;
    // setpointData.Uplift = `${this.getUplift} ${this.getDefermentUnits}`;
    // tasqFeedbackModule.postComment({
    //   tasq: this.tasq,
    //   selection: 'YES',
	  // setpointData,
    // });

    tasqFeedbackModule.setResponseSelectionAction('NO');

    await setpointV2Module.postSetpointJobResponse({ predictionID: this.tasq.id, workflowId: this.tasq.workflowTasqId, accepted: true });
    const jobFormResponsePayload = {
      predictionID: this.tasq.id,
      workflowTaskID: this.tasq.workflowTasqId,
      completeForToday: true,
      closeTasq: false,
      formResponse: [],
      nodeID: this.tasq.wellName,
      validationResponse: tasqFeedbackModule.validationResponse,

    };

    await metaDataModule.postJobFormsResponse(jobFormResponsePayload);

    const date = new Date();
    // await tasqActionsModule.updateTasqStatus({
    //   reason: 'Setpoint accepted, waiting 7 days for results',
    //   date: date.setDate(date.getDate() + 7),
    // });

    const successText = 'Setpoint accepted!';
    await tasqsListModule.getRawTasq({ PredictionID: this.tasq.id });
    // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.submittingSetpointData = false;
    if (tasqsListModule.activePage === 'Kanban') {
      this.$router.push({ name: 'TasqsKanban' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else if (tasqsListModule.activePage === 'Workflow') {
      this.$router.push({ name: 'Workflow' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else if (tasqsListModule.activePage === 'Operations') {
      this.$router.push({ name: 'Operations' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else if (tasqsListModule.activePage === 'Groups') {
      this.$router.push({ name: 'Groups' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else {
      this.$router.push({ name: 'Tasqs' });
    }
    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  async dismissSetpointForNow() {
    let successText = 'Success'!;
    this.submittingSetpointData = true;
    const date = new Date();
    successText = 'Successfully added to waitlist!';
    await setpointV2Module.postSetpointJobResponse({ predictionID: this.tasq.id, workflowId: this.tasq.workflowTasqId, accepted: false });
    await tasqActionsModule.updateTasqStatus({
      reason: 'Setpoint dismissed for 7 days',
      date: date.setDate(date.getDate() + 7),
    });

    await tasqsListModule.getRawTasq({ PredictionID: this.tasq.id });

    this.submittingSetpointData = false;

    if (tasqsListModule.activePage === 'Kanban') {
      this.$router.push({ name: 'TasqsKanban' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else if (tasqsListModule.activePage === 'Workflow') {
      this.$router.push({ name: 'Workflow' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else if (tasqsListModule.activePage === 'Operations') {
      this.$router.push({ name: 'Operations' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else if (tasqsListModule.activePage === 'Groups') {
      this.$router.push({ name: 'Groups' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else {
      this.$router.push({ name: 'Tasqs' });
    }
    //   this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');

    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  async doNotAcceptSetpointChanges() {
    this.comment = this.setpointComment;
    this.submittingSetpointData = true;
    this.selectionAction = 'NO';
    // @ts-ignore
    this.reason = ['Setpoint rejected'];

    const successText = 'Setpoint rejected';
    await tasqsListModule.getRawTasq({ PredictionID: this.tasq.id });
    // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
    this.submittingSetpointData = false;
    if (tasqsListModule.activePage === 'Kanban') {
      this.$router.push({ name: 'TasqsKanban' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else if (tasqsListModule.activePage === 'Workflow') {
      this.$router.push({ name: 'Workflow' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else if (tasqsListModule.activePage === 'Operations') {
      this.$router.push({ name: 'Operations' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else if (tasqsListModule.activePage === 'Groups') {
      this.$router.push({ name: 'Groups' });
      tasqsListModule.setKanbanSelectedTasq('');
    } else {
      this.$router.push({ name: 'Tasqs' });
    }
    this.$eventBus.$emit(SHOW_ALERT, successText);
  }

  closeSetpointModal() {
	  this.showJobDetailsPopup = false;
  }

  chartData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: false,
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
		  {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: '2021-08-21T13:35',
          borderColor: 'rgba(255,255,255,0.7)',
          borderDash: [9, 7],
          // label: {
          //   content: "TODAY",
          //   enabled: true,
          //   position: "top"
          // }
		  },
	  ],
    },
  }

  chartCurrentData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartCurrentOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255,255,255,0.7)',
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
	  ],
    },
  }

  chartBestData: any = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: '#2CE6C2',
      borderWidth: 1.5,
      pointBackgroundColor: '#0076FF',
      pointBorderColor: '#0076FF',
      pointRadius: 0,
      backgroundColor: 'rgba(44,230,194,0.1)',
      fill: true,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  };

  chartBestOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: '#FFFFFF',
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      mode: 'x',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => `${new Date(tooltipItems[0].xLabel).toLocaleString('en-us', {
          month: '2-digit',
          day: '2-digit',
        })} ${new Date(tooltipItems[0].xLabel).toLocaleTimeString('en-us', {
          hour: '2-digit', minute: '2-digit',
        })}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          minRotation: 28,
          autoSkipPadding: 5,
          maxTicksLimit: 20,
        },
        time: {
          unit: 'day',
          displayFormats: {
            hour: 'h:mm A',
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        gridLines: {
          color: 'rgb(113, 122, 139)',
        },
        ticks: {
          fontColor: 'rgb(113, 122, 139)',
          maxTicksLimit: 4,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
          callback: (value) => Number(value).toFixed(),
        },
      }],
    },
    annotation: {
      annotations: [
	  ],
    },
  }

  showSetpointDataFromHistory(setpointData) {
  }

  mainTitleValue() {
    return 'What action was taken?';
  }

  get producingTasq() {
    return assetsModule.activeTasq;
  }

  get currentWellType(): any {
    if (this.producingTasq) {
      this.$emit('update-prediction');
      return 'producing';
    }
	  return this.$route.query.type;
  }

  showSetpointAnalysisView() {
	  this.showJobDetailsPopup = true;
  }

  get tasq() {
	  if (tasqsListModule.isBatchResponding) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
		  return tasqsListModule.activeTasq as TasqJob;
	  }
    if (this.currentWellType == 'producing') {
      return assetsModule.activeTasq as TasqJob;
    }
    return tasqsListModule.activeTasq as TasqJob;
  }

  getFormattedDatetime() {
    if (!this.datetime) return '';
    return DateTime.fromISO(this.datetime).toFormat('hha, LLL d, y');
  }

  get tasqResponseData() {
    if (workflowModule.v2ResponseData) {
      return workflowModule.v2ResponseData;
    }
    return null;
  }

  get nextButtonTitle() {
    return 'Continue';
  }

  get identifyingSubText() {
    if (this.tasq && this.tasq.isManuallyAdded) {
      return `Has this ${this.tasq.predictionType} job been performed?`;
    }
    return '';
  }

  get identifyingText() {
    if (!this.tasq) {
      return '';
    }
    // if (this.tasq.completed) return 'This tasq has been closed'
    if ((this.tasq || {}).noComms) return 'Has this been resolved?';
    if ((this.tasq || {}).wellTestData) return 'Has this well test been completed?';
    if (this.tasq.engineerType === this.$getConst('TASQ_WORKFLOW_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_PROLONGED_ANOMALY_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')
      || this.tasq.predictionType === 'Reject this'
    ) return 'Was an action taken?';
    if ((this.tasqResponseData || {}).ResponseData) return 'Please update your feedback:';
    if (this.tasq.engineerType != null && this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')) {
      return 'Is there a change in well behavior?';
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
      return 'Is this well off target?';
    }
    if (this.tasq && this.tasq.isManuallyAdded) {
      return this.tasq.predictionType;
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_PREDICTION_TYPE')) {
      return 'What would you like to do?';
    }
    return this.tasq.engineerType === 'issue' || !this.tasq
      ? 'Is there an issue here?' : `Did Tasq correctly identify the ${this.tasq?.engineerType}?`;
  }

  get activeActionText() {
    let text = '';
    if (!this.tasq) {
      return text;
    }
    if (
      this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')
      || (this.tasq.engineerType === this.$getConst('TASQ_COMPLETED_TYPE') && this.tasq.predictionType === 'state change')
    ) {
      if (this.selectionAction === this.selectionActions[0].value) {
        text = 'Please select type of change:';
      }
    }
    if (this.tasq.engineerType === this.$getConst('TASQ_FAILURE_TYPE')) {
      if (this.selectionAction === this.selectionActions[1].value) {
        text = 'Please select the reason:';
      }
    }
    if (this.tasq.engineerType === 'issue') {
      if (this.selectionAction === this.selectionActions[0].value) {
        text = 'Please select the reason:';
      }
    }
    return text;
  }

  get possibleReasons() {
    let reasons = this.standardReasons;
    if (
      this.tasq
      && (this.tasq.engineerType === this.$getConst('TASQ_STATE_CHANGE_TYPE')
      || this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE')
      || this.tasq.engineerType === 'issue'
      || (this.$getConst('TASQ_COMPLETED_TYPE') && this.tasq.predictionType === 'state change')
      || (this.$getConst('TASQ_OFF_TARGET_TYPE') && this.tasq.predictionType === this.$getConst('TASQ_OFF_TARGET_TYPE')))
    ) {
      reasons = this.standardReasons;
      if (this.tasq.predictionType === this.$getConst('TASQ_OFF_TARGET_TYPE')) {
        reasons.push('Data issue');
        reasons.push('Downhole solids');
      }
    }

    reasons = [...new Set(reasons)];
    reasons = reasons.sort();
    if (!reasons.includes('Other')) {
      reasons.push('Other');
    }
    return reasons;
  }

  get v2ResponseData() {
	  return workflowModule.v2ResponseData;
  }

  get events() {
    return tasqsListModule.tasqEventHistoryList;
    // return tasqProductionDataChartModule.wellHistory;
  }

  get getWellHistory() {
    return workflowModule.wellHistory;
  }

  get isBatchResponding() {
	  return tasqsListModule.isBatchResponding;
  }

  fetchActions() {
    let forms: any = metaDataModule.formBuilderData;

    forms = forms.map((form) => ({
      validateForToday: false,
      isCompleteForToday: false,
      ...form,
    }));
    forms = forms.reduce((r, a) => {
      if (!a.category) {
        a.category = 'None';
      }
      r[a.category] = r[a.category] || [];
      r[a.category].push(a);
      return r;
    }, Object.create(null));
    const actionBuilderActions = Object.keys(forms).map((key) => ({ title: key, actions: forms[key] }));
    tasqFeedbackModule.setAvailableForms(actionBuilderActions);
    // console.log(this.actionBuilderActions);
    // this.dataLoading = false;
  }

    resizeTrigger: any = null;

    resizeLoading = false;

    resizeWindow() {
      this.resizeLoading = true;
      this.$nextTick(() => {
        this.resizeLoading = false;
      });
    }

    async created() {
      this.resizeTrigger = window.addEventListener('resize', this.resizeWindow);

      this.fetchActions();
      this.resetFeedback();
	  if (tasqsListModule.isBatchResponding) {
		  // tasqFeedbackModule.resetAllData();
		  tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
		  return tasqsListModule.activeTasq as TasqJob;
	  }

      this.$eventBus.$on(CHART_CLICK_EVENT, (date) => {
        if (this.stepper === 4) {
          this.datetime = date;
        }
      });

      if (this.tasq && this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')) {
      // this.setupSetpointPage();
      } else {
      // await this.fetchActions();
        tasqFeedbackModule.setupPage();
      }
      this.setCommentText();
      this.getPredictionList();
    }

    async setupSetpointPage() {
	  this.isLoadingSetpointData = true;
	  this.chartLoaded = false;
      await this.changeChartTime();

      const { last_7 } = setpointV2Module.tasqMCFData;
      const projected_3 = setpointV2Module.tasqMCFData.best_3;

      const lift_pressure_current = setpointV2Module.tasqListPressureData.Current;
      const lift_pressure_best = setpointV2Module.tasqListPressureData.Best;

      const trying: any[] = [];
      for (let x = 0; x < lift_pressure_current.length; x++) {
        const d = new Date();
 		d.setDate(d.getDate() - x);
        trying.push(d.toLocaleString());
      }

      this.chartCurrentData = {
        labels: trying,
        datasets: [{
          label: 'Current',
          // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
          data: lift_pressure_current,
          borderColor: '#2CE6C2',
          borderWidth: 1.5,
          pointBackgroundColor: '#0076FF',
          pointBorderColor: '#0076FF',
          pointRadius: 0,
          backgroundColor: 'rgba(44,230,194,0.1)',
          fill: true,
          showLine: true,
          tension: 0,
          spanGaps: true,
          pointHitRadius: 5,
          pointHoverRadius: 0,
        }, {
          label: 'Optimized',
          // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
          data: lift_pressure_best,
          borderColor: '#e66d2c',
          borderWidth: 1.5,
          pointBackgroundColor: '#0076FF',
          pointBorderColor: '#0076FF',
          pointRadius: 0,
          backgroundColor: 'rgba(230, 109, 44,0.1)',
          fill: true,
          showLine: true,
          tension: 0,
          spanGaps: true,
          pointHitRadius: 5,
          pointHoverRadius: 0,
        },
        ],
      };

      this.chartData = {
        labels: ['2021-08-21T13:05', '2021-08-21T13:10', '2021-08-21T13:15', '2021-08-21T13:20', '2021-08-21T13:25', '2021-08-21T13:30', '2021-08-21T13:35', '2021-08-21T13:40', '2021-08-21T13:45'],
        datasets: [{
          label: '',
          // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
          data: last_7,
          borderColor: '#2CE6C2',
          borderWidth: 1.5,
          pointBackgroundColor: '#0076FF',
          pointBorderColor: '#0076FF',
          pointRadius: 0,
          backgroundColor: 'rgba(44,230,194,0.1)',
          fill: true,
          showLine: true,
          tension: 0,
          spanGaps: true,
          pointHitRadius: 5,
          pointHoverRadius: 0,
        }, {
          label: '',
          // "data": [270.2, 269.2, 268.1, 267.1, 267.3, 267.5, 267.7, 268.3, 268.8, 269.4, 268.8, 268.3, 267.7, 267.8, 267.9, 268, 269.3, 270.7, 272, 271.1],
          data: [null, null, null, null, null, null, ...projected_3],
          borderColor: '#2CE6C2',
          borderWidth: 1.5,
          pointBackgroundColor: '#0076FF',
          pointBorderColor: '#0076FF',
          pointRadius: 0,
          backgroundColor: 'rgba(44,230,194,0.1)',
          fill: true,
          showLine: true,
          tension: 0,
          spanGaps: true,
          pointHitRadius: 5,
          pointHoverRadius: 0,
        },
        ],
      };

      this.chartLoaded = true;
      this.isLoadingSetpointData = false;
    }

    async changeChartTime(value = 14) {
	  await setpointV2Module.getSetpointRecV2ForTasq({ wellName: this.tasq.wellName });
    }

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async resetDefermentPage() {
      this.defermentLabelingModal = false;
      await this.sleep(0.1);
      this.defermentLabelingModal = true;
    }

    // eslint-disable-next-line consistent-return
    handlePossibleActionsChange(value) {
      if (this.tasq.predictionType === this.$getConst('TASQ_FAILURE_PREDICTION_TYPE')) {
        return value;
      }
    }

    backHandler() {
      tasqFeedbackModule.setStepper(this.stepper - 1);
    }

    async returnedToNormal() {
	  this.returnedToNormalLoading = true;
      await this.handleStep(null, true, false, {});
      this.returnedToNormalLoading = false;
    //   tasqFeedbackModule.setStepper(this.stepper - 1)
    //   tasqFeedbackModule.setupPage(true)
    }

    async reassignAndCompleteTasq() {
      this.reassignCompletePopupFocused = false;
      this.isSavingProcedureStep = true;
      const successText = 'Successfully reassigned!';

      if (tasqFeedbackModule.validationResponse.selectionAction === '') {
      // successText = 'Successfully completed and reassigned!';
        await this.handleStepV2(null, false);
        await sleep(8000);
      } else if (tasqFeedbackModule.validationResponse.selectionAction !== '') {
        this.handleStep(null, false);
      }

      this.action.comment = this.reassignmentComment;

      await tasqActionsModule.reassignTasq(this.action);

      this.action = {};

      // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);

      this.isSavingProcedureStep = false;

      // await tasqsListModule.getTasqs({ val: tasqsListModule.activeListType });
    }

    validateDialog(type) {
      if (type === 'wait') {
        if (!this.action.date) {
          this.actionErrors.push('date');
        }
        if (!this.action.startDate) {
          this.actionErrors.push('startDate');
        }
        if (!this.action.reason) {
          this.actionErrors.push('reason');
        }
      }
    }

    async waitingOnAndCompleteTasq() {
      this.actionErrors = [];
      this.validateDialog('wait');
      if (this.actionErrors && this.actionErrors.length) {
        return;
      }
      this.waitingOnCompletePopupFocused = false;
      this.isSavingProcedureStep = true;
      const successText = 'Successfully updated status!';

      if (tasqFeedbackModule.validationResponse.selectionAction === '') {
        await this.handleStepV2(null, false);
        await sleep(8000);
      } else if (tasqFeedbackModule.validationResponse.selectionAction !== '') {
        this.handleStep(null, false);
      }

      await tasqActionsModule.updateTasqStatus(this.action);

      this.action = {
        assetType: '',
        type: '',
        title: '',
        confirmText: '',
        reason: [],
        person: '',
        comment: '',
        startDate: (new Date()).toDateString(),
        date: '',
        well: '',
        jobType: '',
        frequency: 'Today',
        delayedDays: 0,
        trigger: 'Feedback',
      };

      // await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);

      this.isSavingProcedureStep = false;
    }

    prepareHandleStep(selectionAction = null, markAsComplete = false) {
    // console.log(this.finishCompletionSectionButton);
      if (this.finishCompletionSectionButton == 'Next' && this.stepper === 3) {
        const data = tasqFeedbackModule.actionsSelectedInputs.find((action) => action.id !== tasqFeedbackModule.selectedActionInputTab);
        if (data) {
        // console.log('prepareHandleStep');
          tasqFeedbackModule.setSelectedActionInputTab(data.id);
        }
      } else if (this.finishCompletionSectionButton == 'Complete & Reassign') {
        this.reassignCompletePopupFocused = true;
      } else if (this.finishCompletionSectionButton == 'Create waiting on status') {

      } else if (this.finishCompletionSectionButton == 'Schedule this Tasq') {
        this.showModal = true;
      } else {
        this.handleStep(selectionAction, markAsComplete);
      }
    }

    prepareHandleStepV2(input) {
      const { selectionType, formResponse } = input;
      if (selectionType == 'complete') {
        if (tasqFeedbackModule.validationResponse.selectionAction === '') {
          this.handleStepV2(null, true);
        } else {
          this.handleStep(null, true, false, formResponse);
        }
      } else if (selectionType == 'reassign') {
        this.reassignCompletePopupFocused = true;
      } else if (selectionType == 'waitingOnStatus') {
        this.waitingOnCompletePopupFocused = true;
      } else if (selectionType == 'schedule') {
        this.showModal = true;
        if (tasqFeedbackModule.validationResponse.selectionAction === '') {
          this.handleStepV2(null, true);
        } else {
          this.handleStep(null, true, false, formResponse);
        }
      }
    }

    async handleStepFromDefermentLabel(comment) {
    // console.log(comment)
      if (comment) {
        this.comment = `${comment} - `;
      }
      await this.handleStep(null, true, true, {});
      this.comment = '';
    }

  validationResponse: any = {
    sectionAction: null,
    selectionActionComment: '',
  }

  async handleStep(selectionAction: any = null, markAsComplete = false, fromDefermentLabeling = false, formResponse = {}) {
    // console.log('I am called from submit');

	  const initial_step = this.stepper;

    if (selectionAction != null && selectionAction.value === 'NO') {
      if (this.tasq && this.tasq.engineerType === this.$getConst('TASQ_OFF_TARGET_TYPE') && !this.isBatchResponding) {
        this.defermentLabelingModal = true;
        return;
      }
      markAsComplete = true;
    }
	  if (selectionAction != null) {
		  // @ts-ignore
      tasqFeedbackModule.setResponseSelectionAction(selectionAction!.value);
	  } else if (fromDefermentLabeling) {
		  tasqFeedbackModule.setResponseSelectionAction('NO');
	  }
    this.isSavingProcedureStep = true;

    const jobFormResponsePayload = {
      predictionID: this.tasq.id,
      workflowTaskID: this.tasq.workflowTasqId,
      completeForToday: markAsComplete,
      closeTasq: false,
      formResponse: tasqFeedbackModule.selectedForms,
      nodeID: this.tasq.wellName,
      validationResponse: tasqFeedbackModule.validationResponse,

    };

    if (tasqFeedbackModule.validationResponse.selectionAction !== '') {
      await metaDataModule.postJobFormsResponse(jobFormResponsePayload);
      // await tasqFeedbackModule.leaveFeedbackV2(leaveFeedback);
    }
    this.setCommentText();
    this.showResponseSectionDropdown = true;
    this.showCommentSectionDropdown = false;
    this.showActivitySectionDropdown = false;

    this.childKey += 1;
    	this.isSavingProcedureStep = false;

    // await workflowModule.getJobResponseV2(this.tasq?.id);

    await tasqsListModule.getRawTasq({ PredictionID: this.tasq.id });


    if (markAsComplete || initial_step == 4) {
      if (this.isOpsPage) {
        this.$emit('close-dialog');
      }
      this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');
      const currentName: any = this.$route.name;
      this.$router.push({ name: currentName });
      // await tasqsListModule.getTasqs({ val: tasqsListModule.activeListType });
    } else {
      await tasqsListModule.getTasq({ PredictionID: this.tasq.id });
      tasqFeedbackModule.setupPage();

      await sleep(1000);
      const promises: any = [];

      if (this.tasq.workflowTasqId != null) {
        promises.push(tasqsListModule.getTasqEventHistoryList(this.tasq.workflowTasqId));
      } else if (this.tasq.scheduledJobID != null) {
        promises.push(tasqsListModule.getTasqEventHistoryList(this.tasq.scheduledJobID));
      }

      promises.push(workflowModule.getWellHistory(this.tasq.wellName));

      Promise.all(promises).then(() => {
      // console.log('resolved handle step v1');
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
    }
  }

  async handleStepV2(selectionAction = null, markAsComplete = false, fromDefermentLabeling = false) {
     	this.isSavingProcedureStep = true;
    const initial_step = this.stepper;
    const { selectedForms } = tasqFeedbackModule;
    const predictionList: any = (this.tasq.predictionsList);

    selectedForms.forEach(async (form) => {
      const findCurrentActionTasq = predictionList ? predictionList[form.category] : null;
      let predictionID: any = '';
      if (findCurrentActionTasq && findCurrentActionTasq.ParentWorkflowTaskID === this.tasq.workflowTasqId) {
        // console.log(findCurrentActionTasq);
        predictionID = (findCurrentActionTasq.PredictionID);
      } else {
      // console.log(this.activeActionTab)
        predictionID = await tasqActionsModule.addActionTasq({ actionName: form.category, parentWorkflowID: this.tasq.workflowTasqId, well: this.tasq.wellName });
      }

      tasqsListModule.setIsActionUnReleated(true);
      tasqsListModule.checkTasq(predictionID);

      const newTasq: any = await tasqsListModule.getRawTasq({ PredictionID: predictionID });

      const selectedFormAction = selectedForms.filter((action) => action.category === form.category);

      const jobFormResponsePayload = {
        predictionID: newTasq.id,
        workflowTaskID: this.tasq.workflowTasqId,
        completeForToday: markAsComplete,
        closeTasq: false,
        formResponse: selectedFormAction,
        nodeID: this.tasq.wellName,
        validationResponse: tasqFeedbackModule.validationResponse,

      };

      if (tasqFeedbackModule.validationResponse.selectionAction !== '' && !fromDefermentLabeling) {
        await metaDataModule.postJobFormsResponse(jobFormResponsePayload);
      // await tasqFeedbackModule.leaveFeedbackV2(leaveFeedback);
      }

      // await tasqFeedbackModule.leaveFeedbackV2(leaveFeedback);
    });
    tasqFeedbackModule.setStepper(1);
    await sleep(3000);

    await tasqsListModule.getRawTasq({ PredictionID: this.tasq.id });

    this.$emit('update-predictions');

    if (this.tasq.workflowTasqId != null) {
      tasqsListModule.getTasqEventHistoryList(this.tasq.workflowTasqId);
    } else if (this.tasq.scheduledJobID != null) {
      tasqsListModule.getTasqEventHistoryList(this.tasq.scheduledJobID);
    }

    workflowModule.getWellHistory(this.tasq.wellName);

    if (markAsComplete === false) {
      this.isSavingProcedureStep = false;
      tasqFeedbackModule.setupPage();
      return;
    }
    this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');

    this.isSavingProcedureStep = false;
    tasqFeedbackModule.setupPage();
  }

  setCommentText() {
	  if (this.stepper == 1) {
		  this.comment = tasqFeedbackModule.validationResponse.selectionActionComment;
	  }
  }

  handleStepClick(step) {
    if (!step.active) return;
    // this.finishCompletionSectionButton = "Complete for today"
    tasqFeedbackModule.setStepper(step.id);
    // tasqFeedbackModule.setupPage(true);
    this.setCommentText();
  }

  handleTasqChange() {
    this.startDataLoading();
    this.resetFeedback();

    if (this.tasq && this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE')) {
      this.selectionActions = [{
        text: 'Updated setpoint',
        value: 'YES',
        style: 'FULL',
      }, {
        text: 'Not now',
        value: 'NO',
        style: 'Full',
      }];
    } else if (this.tasq && this.tasq.engineerType === this.$getConst('TASQ_WELL_TEST_TYPE')) {
      this.selectionActions = [
        {
          text: 'Yes',
          value: 'YES',
		  style: 'FULL',
        },
        {
          text: 'No',
          value: 'NO',
		  style: 'FULL',
        },
      ];
    } else if (this.tasq && this.tasq.isManuallyAdded) {
      this.selectionActions = [{
        text: 'Yes',
        value: 'YES',
		  style: 'FULL',
      },
      {
        text: 'No',
        value: 'NO',
		  style: 'FULL',
      }];
    } else if (this.tasq && this.tasq.completed) {
      this.selectionActions = [];
    } else {
      this.selectionActions = [
        {
          text: 'Yes',
          value: 'YES',
		  style: 'FULL',
        },
        {
          text: 'No',
          value: 'NO',
		  style: 'FULL',
        },
        {
          text: 'Not sure',
          value: 'DONT_KNOW',
		  style: 'SUB',
        },
      ];
    }
    this.stopDataLoading();
  }

  resetFeedback() {
    tasqFeedbackModule.setStepper(1);
    this.selectionAction = '';
    this.datetime = '';
    this.jobAction = [];
    this.comment = '';
    this.reason = [];
    this.showComment = false;
    this.showActionComment = false;
  }

  @Debounce(1000)
  async leaveFeedback(
    skipDateTime = false,
    markAsComplete = false,
    shouldPostJobComment = false,
    completeForToday = false,
  ) {
    // @ts-ignore
    if (this.jobAction.find((j) => j.includes('Reassign'))) {
      // eslint-disable-next-line no-param-reassign
      markAsComplete = false;
      this.$emit('reassign-tasq');
    }
    if (this.datetime || skipDateTime) {
      this.startDataLoading();

      await tasqsListModule.getRawTasq({ PredictionID: this.tasq.id });

      // await tasqFeedbackModule.leaveFeedbackV2({
      //   tasq: this.tasq,
      //   // @ts-ignore
      //   comment: this.comment,
      //   validate: '',
      //   systems: [],
      //   symptoms: [],
      //   causes: [],
      //   actions: [],
      //   proceduresJSONVersion: '',
      //   markAsComplete,
      //   completedForToday: completeForToday,
      //   shouldPostAsGlobalWellComment: shouldPostJobComment,
      // });
      // @ts-ignore
      if (!this.jobAction.find((j) => j.includes('Reassign'))) {
        if (tasqsListModule.activePage === 'Workflow') {
          this.$router.push({ name: 'Workflow' });
          tasqsListModule.setKanbanSelectedTasq('');
        } else if (tasqsListModule.activePage === 'Kanban') {
          tasqsListModule.setKanbanSelectedTasq('');
          this.$router.push({ name: 'TasqsKanban' });
        } else if (tasqsListModule.activePage === 'Operations') {
          tasqsListModule.setKanbanSelectedTasq('');
          this.$router.push({ name: 'Operations' });
        } else if (tasqsListModule.activePage === 'Groups') {
          this.$router.push({ name: 'Groups' });
          tasqsListModule.setKanbanSelectedTasq('');
        } else {
          this.$router.push({ name: 'Tasqs' });
          this.closeBatchResponseFeedbackSidebar();
        }
        // await tasqsListModule.getTasqs({ val: tasqsListModule.activeListType });
      }
      this.stopDataLoading();
      this.$eventBus.$emit(SHOW_ALERT, 'Feedback saved!');
    }
  }

  openStepDetails(step) {
    (this.foundProcedure as any).steps.forEach((i) => {
      if (step.id !== i.id) {
        Object.assign(i, { showDetails: false });
      }
    });
    Object.assign(step, { showDetails: !step.showDetails });
  }

  updateTasqTime() {
    this.$dialog.show('productionDataModal');
  }

  @Watch('tasq.id', {
    immediate: true,
  })
  onTasqChange() {
    this.handleTasqChange();
  }

  @Watch('jobAction')
  onJobActionChange(value) {
    if (value.find((j) => j.includes('Other'))) {
      this.showActionComment = true;
    }
  }

  @Watch('comment')
  onValidationCommentChange(value) {
    tasqFeedbackModule.setValidateResponseComment(value);
  }

  @Watch('showComment')
  onShowComment(value) {
    if (value) {
      this.sleep(300).then(() => {
        const scrollView = document.getElementById('tasqFeedbackScrollView')!;
        scrollView.scrollTo(0, scrollView.scrollHeight);
      });
    }
  }

  @Watch('showActionComment')
  onShowActionComment(value) {
    if (value) {
      this.sleep(300).then(() => {
        const scrollView = document.getElementById('tasqFeedbackScrollView')!;
        scrollView.scrollTo(0, scrollView.scrollHeight);
      });
    }
  }

  @Watch('tasqResponseData', {
    immediate: true,
  })
  onTasqResponseChange() {
    this.handleTasqChange();
  }
}

